import React from "react";
import './App.css';
import Routing from "./routing/Routing";

function App() {
  return (
    <>
    <Routing />
    </>
  );
}

export default App;
